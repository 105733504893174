<template>
<div>
    <!-- My Information -->
    <div class="my-info mt-8">
        <div class="vx-row justify-center">
            <div class="vx-col lg:w-2/3 xl:w-1/2 w-full">
                <vx-card>
                    <div class="profile-icon">
                        <span class="main-icon">
                            <img src="../../assets/images/padlock.svg" />
                        </span>
                        <div class="card-title flex-wrap justify-center">
                            <h2 class="text-white w-full">{{ LabelConstant.headingLabelChangeMyPassword }}</h2>
                            <!-- <vs-button title="Help" @click="popupActive=true"  type="filled" icon-pack="feather" icon="icon-help-circle"></vs-button> -->
                        </div>
                    </div>
                    <div class="update-info-form">
                        <form @submit.prevent="checkValidation()" autocomplete="off">
                            <template>
                                <div class="vx-row mb-6">
                                    <div class="vx-col w-full">
                                        <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border :label="LabelConstant.inputLabelNewPassword" name="newPassword" ref="newPassword" v-model="newPassword" v-validate="'required|min:6'" />
                                        <span class="text-danger text-sm">{{ errors.first('newPassword') }}</span>
                                    </div>
                                </div>
                                <div class="vx-row mb-6">
                                    <div class="vx-col w-full">
                                        <vs-input class="w-full" type="password" icon-pack="feather" icon="icon-lock" icon-no-border name="confirmPassword" :label="LabelConstant.inputLabelConfirmNewPassword" v-model="confirmPassword" v-validate="'required|min:6|confirmed:newPassword'" />
                                        <span class="text-danger text-sm">{{ errors.first('confirmPassword') }}</span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full mt-6">
                                        <vs-button type="filled" class="mr-4 mb-2 small-btn" color="primary" button="submit">{{ LabelConstant.buttonLabelUpdateMyPassword }}</vs-button>
                                        <vs-button color="grey" type="border" class="mb-0 md:mb-2 small-btn grey-btn" @click.prevent="cancelChanges()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                    </div>
                                </div>
                            </template>
                        </form>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        newPassword: {
            required: "The New password field is required.",
            min: "The New password must be at least 6 characters long."
        },
        confirmPassword: {
            required: "The Confirm New Password field is required.",
            min: "The Confirm New Password must be at least 6 characters long.",
            confirmed: "The new password and confirmation password do not match."
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal
    },
    data() {
        return {
            /* Popup */
            popupActive: false,
            newPassword: null,
            confirmPassword: null
        };
    },
    methods: {
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        // cancel changes 
        async cancelChanges() {
            this.newPassword = null;
            this.confirmPassword = null;
            setTimeout(()=>{
                this.errors.clear();
            },20);
        },
        //check validaiton 
        async checkValidation() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    this.UpdatePassword();
                }
            });
        },
        // update password api
        async UpdatePassword() {
            this.$vs.loading();
            let updatePassword = {
                Password_New: this.newPassword,
                Password_Confirm: this.confirmPassword
            };
            await this.axios
                .post("/ws/ChangePassword/UpdatePassword", updatePassword)
                .then(() => {
                    this.newPassword = null;
                    this.confirmPassword = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your password has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        }
    }
};
</script>
